/* html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
} */

body {
    top: 0;
    left: 0;
    width: 100%;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-optical-sizing: auto;
    font-variant-numeric: lining-nums;
    font-feature-settings: "lnum";
}